import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  PaletteMode,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { getIconStyle } from './helper';
import Link from 'components/MUI/Link';
import { MenuItemFontStyles } from 'components/shared/PlayerManagementDropdown';
import { trpc } from 'helpers/trpc';
import { authPages } from 'auth/constants';

interface Props {
  isPerformanceLabPage: boolean;
  theme: PaletteMode;
}

const UserProfileDropdown = ({ isPerformanceLabPage, theme }: Props) => {
  const router = useRouter();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const currentUserQuery = trpc.shared.currentUser.useQuery();

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const open = Boolean(anchorEl);

  const customStyles = {
    avatar: {
      width: 24,
      height: 24,
      mr: 2,
    },
    button: getIconStyle(theme, isPerformanceLabPage),
    icon: {
      width: 17,
      height: 17,
    },
    dropdownIcon: {
      color: !isPerformanceLabPage ? 'white' : undefined,
    },
    text: {
      letterSpacing: 'normal',
      textTransform: 'none',
      color: theme === 'dark' || !isPerformanceLabPage ? 'white' : '#595959',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const UserQueryResult = () => {
    const { status } = currentUserQuery;

    if (status === 'pending') {
      return 'Loading...';
    }

    if (status === 'error') {
      return 'Not Logged In ⚠️';
    }

    if (status === 'success') {
      return `${currentUserQuery.data?.user.email}`;
    }

    return '';
  };

  const handleSignOut = () => {
    router.push(
      `${authPages.signOut}?redirectTo=${encodeURIComponent(
        window.location.href
      )}`
    );
  };
  const handleUserSettings = () => router.push('/usersettings');

  return (
    <Box>
      <Button
        variant="text"
        onClick={(event) => setAnchorEl(event.currentTarget!)}
        sx={customStyles.button}
      >
        <Avatar
          sx={customStyles.avatar}
          src={currentUserQuery.data?.user.profileImage}
        />

        {isMobile === false && (
          <Typography component="h6" sx={customStyles.text}>
            {UserQueryResult()}
          </Typography>
        )}

        <ArrowDropDownIcon sx={customStyles.dropdownIcon} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <Link href="/usersettings" sx={customStyles.link}>
          <MenuItem sx={MenuItemFontStyles} onClick={handleUserSettings}>
            <SettingsIcon style={customStyles.icon} />

            <Typography ml={3}>Settings</Typography>
          </MenuItem>
        </Link>

        <MenuItem sx={MenuItemFontStyles} onClick={handleSignOut}>
          <LogoutIcon style={customStyles.icon} />

          <Typography ml={3}>Sign Out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserProfileDropdown;
