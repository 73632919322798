import { Divider, Skeleton, Stack } from '@mui/material';

const skeletonLenght = 5;

const customStyles = {
  playerSkeletonStack: {
    px: 1,
  },
  playerRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  playerSkeletonInnerStack: {
    py: 3,
    alignItems: 'center',
  },
  nameSkeleton: {
    width: '15vw',
    height: 25,
  },
  buttonSkeleton: {
    width: '5vw',
    height: 40,
  },
};

export const playerSessionListSkeleton = (
  <Stack divider={<Divider />} sx={customStyles.playerSkeletonStack}>
    {Array.from({ length: skeletonLenght }).map((_, index) => (
      <Stack key={index} direction="row" sx={customStyles.playerRow}>
        <Skeleton variant="text" sx={customStyles.nameSkeleton} />

        <Stack
          direction="row"
          spacing={4}
          sx={customStyles.playerSkeletonInnerStack}
        >
          <Skeleton variant="text" sx={customStyles.buttonSkeleton} />

          <Skeleton variant="text" sx={customStyles.buttonSkeleton} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);
