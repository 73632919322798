import { MenuItem, MenuList } from '@mui/material';
import Image from 'next/image';

import styles from './pageStyles';
import { MAHD_SPORTS_ACADEMY_NAME } from 'helpers/constants';
import { CurrentUserWithRole } from 'helpers/userRoleHelpersAndTypes';

interface Props {
  currentUser: CurrentUserWithRole;
  isPerformanceLabPage: boolean;
}

const ClubLogo = ({ currentUser, isPerformanceLabPage }: Props) => {
  const isMahdSportsAcademy =
    currentUser?.club.name.toLowerCase() ===
    MAHD_SPORTS_ACADEMY_NAME.toLowerCase();

  return (
    <MenuList
      sx={{
        ...styles.logo,
        backgroundColor: isPerformanceLabPage
          ? 'white'
          : `${currentUser?.club.color}`,
      }}
    >
      <MenuItem disableRipple sx={styles.clubLogoMenuItem}>
        {currentUser?.club.logo && (
          <Image
            src={currentUser?.club.logo}
            alt="logo"
            width={isMahdSportsAcademy ? '64' : '44'}
            height={isMahdSportsAcademy ? '64' : '44'}
          />
        )}
      </MenuItem>
    </MenuList>
  );
};

export default ClubLogo;
