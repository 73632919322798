import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import LoadingButton from 'components/shared/Buttons/LoadingButton';
import { higherLevelScoutRoleIds } from 'helpers/getHighLevelScoutRoles';
import { getPlayerPositionsByRegion } from 'helpers/getPositionsByRegion';
import sortByField from 'helpers/sortObjectsByField';
import { api, RouterOutputs } from 'helpers/trpc/pages';

type PlayerInfo = {
  id: string;
  name: string;
  positionIds: string[];
};
type ClubScout = RouterOutputs['recruitmentCenter']['getProClubScouts'][number];
type Position = RouterOutputs['shared']['getAllPositions'][number];

interface Props {
  anchorEl: null | HTMLElement | HTMLButtonElement;
  handleClose: () => void;
  player: PlayerInfo;
}

const skeletonEntriesCount = 25;
const defaultSearchValue = '';
const positionQueryStaleTimeInSeconds = 300;

export const MenuItemFontStyles = {
  fontSize: '14px',
  padding: '10px 17px',
  lineHeight: '1',
  letterSpacing: 0,
};

const PlayerManagementDropdown = ({ anchorEl, handleClose, player }: Props) => {
  const open = Boolean(anchorEl);
  const [selectedPositions, setSelectedPositions] = useState<Position[]>([]);
  const [selectedScout, setSelectedScout] = useState<ClubScout | null>(null);
  const [positionModalOpen, setPositionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(defaultSearchValue);
  const isHigherLevelScout = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: currentUser } = api.shared.currentUser.useQuery();

  const {
    data: activeClubScouts,
    refetch: refetchActiveClubScouts,
    isFetching: isActiveClubScoutsFetching,
  } = api.shared.getActiveClubScouts.useQuery(undefined, { enabled: false });

  const {
    data: clubScouts,
    isFetching: isScoutsFetching,
    refetch: refetchClubScouts,
  } = api.recruitmentCenter.getProClubScouts.useQuery(undefined, {
    enabled: false,
  });

  const { data: positions } = api.shared.getAllPositions.useQuery(undefined, {
    enabled: positionModalOpen,
    staleTime: positionQueryStaleTimeInSeconds * 1000,
  });

  const addToScoutAppWatchList =
    api.recruitmentCenter.updateScoutPlayerWatchPositions.useMutation();

  const isDataFetching = isScoutsFetching || isActiveClubScoutsFetching;

  const sortedClubScouts = useMemo(() => {
    if (isHigherLevelScout.current) {
      const sortedScouts = sortByField(clubScouts ?? [], 'name');

      const filteredScouts = sortedScouts.filter((scout) =>
        scout.name?.toLowerCase().includes(searchInput.toLowerCase())
      );

      const activeScouts = filteredScouts.filter((scout) =>
        activeClubScouts?.some(
          (activeScout) => activeScout.scoutId === scout.id
        )
      );

      return activeScouts;
    }

    return clubScouts?.filter(
      (scout) => scout.id === currentUser?.user.scoutId_aiscout
    );
  }, [
    activeClubScouts,
    clubScouts,
    currentUser?.user.scoutId_aiscout,
    searchInput,
  ]);

  const setDefaultPlayerPositions = useCallback(() => {
    setSelectedPositions(
      positions?.filter((position) =>
        player.positionIds.includes(position.id)
      ) ?? []
    );
  }, [player.positionIds, positions]);

  const addPlayerToScoutAppWatchList = async (
    scout: ClubScout,
    positionsList: Position[]
  ) => {
    setLoading(true);

    try {
      await addToScoutAppWatchList.mutateAsync({
        playerId: player.id,
        scoutId: scout.id,
        positionIds: positionsList.map((position) => position.id),
      });

      enqueueSnackbar(
        `${player.name} has been added to ${scout.name} watchlist`,
        { variant: 'success' }
      );

      onClose();

      setPositionModalOpen(false);
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }

    setDefaultPlayerPositions();

    setLoading(false);
  };

  const addPlayerToList = (scout: ClubScout) => {
    if (selectedPositions.length === 0) {
      setPositionModalOpen(true);

      return;
    }

    addPlayerToScoutAppWatchList(scout, selectedPositions);
  };

  const onClose = () => {
    setSearchInput(defaultSearchValue);
    handleClose();
  };

  const positionModal = (
    <Dialog
      open={positionModalOpen}
      onClose={() => setPositionModalOpen(false)}
      PaperProps={{ sx: customStyles.positionDialog }}
      fullWidth
    >
      <DialogTitle>Add Position</DialogTitle>

      <Typography sx={customStyles.positionModalText}>
        {player.name} does not have position. Please select a position
      </Typography>

      <DialogContent>
        {positions ? (
          <>
            <Autocomplete
              multiple
              disableCloseOnSelect
              value={selectedPositions}
              options={getPlayerPositionsByRegion(positions)}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                setSelectedPositions(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  label="Position"
                />
              )}
            />

            <DialogActions sx={customStyles.dialogActions}>
              <Button
                variant="secondary"
                onClick={() => setPositionModalOpen(false)}
              >
                Cancel
              </Button>

              <LoadingButton
                label="Add"
                disabled={selectedPositions.length === 0}
                loading={loading}
                loadingLabel="Adding"
                variant="primary"
                onClick={() =>
                  addPlayerToScoutAppWatchList(
                    selectedScout!,
                    selectedPositions
                  )
                }
              />
            </DialogActions>
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={customStyles.autocompleteSkeleton}
          />
        )}
      </DialogContent>
    </Dialog>
  );

  const scoutListSkeleton = (
    <Box sx={customStyles.scoutListSkeletonBox}>
      {Array.from({ length: skeletonEntriesCount }).map((_, index) => (
        <Skeleton
          key={index}
          variant="text"
          sx={customStyles.scoutListSkeleton}
        />
      ))}
    </Box>
  );

  useEffect(() => {
    if (currentUser?.user.roleId) {
      const higherLevelScoutValue = higherLevelScoutRoleIds.includes(
        currentUser.user.roleId
      );

      isHigherLevelScout.current = higherLevelScoutValue;
    }
  }, [currentUser]);

  useEffect(() => {
    setDefaultPlayerPositions();
  }, [setDefaultPlayerPositions]);

  useEffect(() => {
    refetchActiveClubScouts();
    refetchClubScouts();
  }, [refetchActiveClubScouts, refetchClubScouts]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={customStyles.menu}
        PaperProps={{
          sx: customStyles.menuPaper,
        }}
      >
        {isHigherLevelScout.current && (
          <Box sx={customStyles.textField}>
            <TextField
              size="small"
              placeholder="Search scouts"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={customStyles.searchIcon} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(event) => event.stopPropagation()}
              onChange={(event) => setSearchInput(event.target.value)}
            />
          </Box>
        )}

        {isDataFetching
          ? scoutListSkeleton
          : sortedClubScouts?.map((scout) => (
              <MenuItem
                key={scout.id}
                onClick={(event) => {
                  event.stopPropagation();
                  addPlayerToList(scout);
                  setSelectedScout(scout);
                }}
              >
                <Stack direction="row" alignItems="center" spacing={4}>
                  <Avatar src={scout.profile_image_path} />

                  <Typography variant="h3" mb={0}>
                    {scout.name}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
      </Menu>
      {positionModal}
    </>
  );
};

const customStyles = {
  positionModalText: {
    px: 6,
  },
  dialogActions: {
    mt: 2,
  },
  autocompleteSkeleton: {
    height: 40,
  },
  scoutListSkeletonBox: {
    px: 4,
  },
  scoutListSkeleton: {
    lineHeight: 2,
  },
  menu: {
    maxHeight: '75vh',
  },
  textField: {
    py: 2,
  },
  menuPaper: {
    backgroundColor: '#F6F6F6',
    px: 2,
    minWidth: '20vw',
  },
  searchIcon: {
    color: 'black',
  },
  positionDialog: {
    backgroundColor: '#F6F6F6',
  },
};

export default PlayerManagementDropdown;
