import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { RouterOutputs } from 'helpers/trpc';

interface Props {
  player: RouterOutputs['performanceLab']['getPlayersSearch'][number];
  index: number;
  playersPerPage: number;
  isPerformanceLabPage: boolean;
}

const EplPlayerRow = ({
  player,
  index,
  playersPerPage,
  isPerformanceLabPage,
}: Props) => {
  const isNotLastRow = index + 1 !== playersPerPage;

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar src={player.profileImagePath} />
          <Box>
            <Typography sx={customStyles.playerName}>{player.name}</Typography>
            <Typography>Age: {player.age}</Typography>
          </Box>
        </Stack>

        <IconButton>
          <KeyboardArrowRightIcon
            sx={isPerformanceLabPage ? {} : customStyles.icon}
          />
        </IconButton>
      </Stack>
      {isNotLastRow && <Divider sx={customStyles.divider} />}
    </Box>
  );
};

export default EplPlayerRow;

const customStyles = {
  divider: {
    pb: 2,
  },
  playerName: {
    wordWrap: 'break-word',
    fontSize: 16,
    mb: -0.5,
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  icon: {
    color: 'black',
  },
};
