import Router from 'next/router';

import { fallbackLocale } from './getRegionalDate';
import { Position } from 'server/routers/shared/types';

const nonUSTerm = 'Centre';
const usTerm = 'Center';

export const getPlayerPositionByRegion = (
  position: string | undefined | null
) => {
  const locale = Router.locale ?? fallbackLocale;

  if (!position) return '';

  if (locale === fallbackLocale) return position;

  return position.replaceAll(nonUSTerm, usTerm);
};

export const getPlayerPositionsByRegion = (positionList: Position[]) => {
  const locale = Router.locale ?? fallbackLocale;

  if (locale === fallbackLocale) return positionList;

  const newPositionList =
    positionList?.map((position) => {
      if (position.name?.includes(nonUSTerm)) {
        return {
          ...position,
          name: position.name.replaceAll(nonUSTerm, usTerm) ?? '',
        };
      }

      return position;
    }) ?? [];

  return newPositionList;
};
