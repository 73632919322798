import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';

import { playerSessionListSkeleton } from './skeletons';
import Link from 'components/MUI/Link';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';
import { api } from 'helpers/trpc/pages';
import { PlayerSearchItem } from 'server/routers/performanceLab/types';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedPlayer: PlayerSearchItem;
}

const EPLPlayerSessionsModal = ({
  open,
  handleClose,
  selectedPlayer,
}: Props) => {
  const router = useRouter();

  const isPerformanceLabPage = getPerformanceLabPagePaths().includes(
    router.pathname
  );

  const { data: playerSessions, isFetching: isSessionsFetching } =
    api.performanceLab.getPlayerEplSessions.useQuery({
      playerId: selectedPlayer.id,
    });

  const iconStyle = isPerformanceLabPage ? {} : customStyles.icon;

  const onClose = () => {
    handleClose();
  };

  const titleSection = (
    <DialogTitle sx={customStyles.titleStack}>
      {'Player Search '}

      <IconButton onClick={onClose}>
        <CloseIcon sx={iconStyle} />
      </IconButton>
    </DialogTitle>
  );

  const playerNameSection = (
    <>
      {selectedPlayer !== null ? (
        <Box sx={customStyles.playerNameContainer}>
          <Avatar
            src={selectedPlayer.profileImagePath}
            sx={customStyles.avatar}
          />

          <Typography variant="h3" sx={customStyles.playerName}>
            {selectedPlayer.name + ' EPL Sessions'}
          </Typography>
        </Box>
      ) : (
        <Skeleton variant="text" sx={customStyles.playerCountSkeleton} />
      )}
    </>
  );

  const filteredPlayersSection = (
    <Stack divider={<Divider />} sx={customStyles.filteredPlayersStack}>
      {playerSessions &&
        playerSessions.map((session, index) => (
          <Box
            key={index}
            sx={(theme) => ({
              ...customStyles.link,
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            })}
          >
            <Stack direction="row" sx={customStyles.playerRow}>
              <Stack
                direction="row"
                key={index}
                sx={customStyles.filteredPlayersInnerStack}
              >
                <Typography variant="body1" sx={customStyles.sessionName}>
                  {session!.name}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={3}>
                <Link
                  href={`performancelabreport?userId=${
                    selectedPlayer.userId
                  }&sessionId=${session!.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="primary" sx={customStyles.actionButton}>
                    Report
                  </Button>
                </Link>

                {session!.isActive ? (
                  <Link
                    href={`stationdataentry?sessionId=${session!.id}&playerId=${
                      selectedPlayer.id
                    }&stationId=`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="primary" sx={customStyles.actionButton}>
                      Data Entry
                    </Button>
                  </Link>
                ) : (
                  <Tooltip title="Session is not active" placement="top" arrow>
                    <Button
                      variant="primary"
                      sx={customStyles.actionButton}
                      disabled
                    >
                      Data Entry
                    </Button>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Box>
        ))}
    </Stack>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box sx={customStyles.mainBox}>
        {titleSection}

        <DialogContent>
          {playerNameSection}

          {isSessionsFetching
            ? playerSessionListSkeleton
            : filteredPlayersSection}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default EPLPlayerSessionsModal;

const customStyles = {
  titleStack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pr: 3,
    pb: 1,
  },
  filteredPlayersStack: {
    px: 1,
    maxHeight: 350,
    overflow: 'hidden',
    ':hover': {
      overflowY: 'auto',
    },
  },
  filteredPlayersInnerStack: {
    py: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: 70,
    height: 70,
    mb: 3,
  },
  playerName: {
    wordWrap: 'break-word',
    fontSize: 16,
    mb: 0,
  },
  sessionName: {
    fontSize: 15,
    mb: 0,
  },
  paginationStack: {
    px: 6,
    pt: 2,
    pb: 4,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 12,
  },
  pagination: {
    mt: 2,
  },
  mainBox: {
    width: 600,
    overflowX: 'hidden',
    height: 600,
  },
  avatarSkeleton: {
    width: 50,
    height: 50,
  },
  nameSkeleton: {
    width: '15vw',
    height: 25,
  },
  playerSkeletonStack: {
    px: 1,
  },
  playerSkeletonInnerStack: {
    py: 3,
    alignItems: 'center',
  },
  playerCountBox: {
    mt: 2,
    pl: 0,
  },
  playerCountSkeleton: {
    width: 160,
  },
  playerLink: {
    textDecoration: 'none',
  },
  playerRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  skeletonArrow: {
    width: 25,
    height: 25,
  },
  paginationSkeletonText: {
    width: '30%',
    height: 30,
  },
  paginationNumbersSkeleton: {
    mt: 2,
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    py: 2,
  },
  search: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      background: '#F6F6F6',
    },
  },
  icon: {
    color: 'black',
  },
  playerNameContainer: {
    pt: 3,
    pb: 9,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    fontSize: 11,
  },
};
