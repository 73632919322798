import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  PaginationItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import EplPlayerRow from './EplPlayerRow';
import PlayerSearchModalSkeletons from 'components/shared/PlayerSearchModal/Skeletons';
import ShowingPage from 'components/shared/ShowingPage';
import TextSearchField from 'components/shared/TextSearchField';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';
import { api } from 'helpers/trpc/pages';
import { PlayerSearchItem } from 'server/routers/performanceLab/types';

interface Props {
  open: boolean;
  handleClose: () => void;
  onPlayerSelected: (player: PlayerSearchItem) => void;
}

const playersPerPage = 5;
const defaultSearchValue = '';
const initialPage = 1;
const defaultPlayerCount = 0;
const initialOffset = 0;
const defaultLastPage = 0;

const EPLPlayerSearchModal = ({
  open,
  handleClose,
  onPlayerSelected,
}: Props) => {
  const router = useRouter();
  const [page, setPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(defaultSearchValue);
  const [playersCount, setPlayersCount] = useState(defaultPlayerCount);
  const [selectedPagePlayers, setSelectedPagePlayers] = useState<
    PlayerSearchItem[]
  >([]);

  const offset = useRef(initialOffset);
  const searchTermLength = useRef(searchTerm.length);

  const isPerformanceLabPage = getPerformanceLabPagePaths().includes(
    router.pathname
  );

  const iconStyle = isPerformanceLabPage ? {} : customStyles.icon;

  const {
    data: players,
    refetch: refetchPlayers,
    isFetching: isPlayersFetching,
  } = api.performanceLab.getPlayersSearch.useQuery(
    { playerName: searchTerm },
    { enabled: false }
  );

  const onClose = () => {
    setPage(initialPage);
    setSearchTerm(defaultSearchValue);

    searchTermLength.current = defaultSearchValue.length;

    handleClose();
  };

  const searchPlayersByName = async (searchValue: string) => {
    setSearchTerm(searchValue);
    setPage(initialPage);

    searchTermLength.current = searchValue.length;
  };

  const setPageData = (pageNumber: number) => {
    setPage(pageNumber);

    const offset = (pageNumber - 1) * playersPerPage;

    if (players) {
      setSelectedPagePlayers(players.slice(offset, playersPerPage + offset));
    }
  };

  const lastPage = playersCount
    ? Math.ceil(playersCount / playersPerPage)
    : defaultLastPage;

  useEffect(() => {
    if (searchTermLength.current > 0) {
      refetchPlayers();
    }
  }, [refetchPlayers, searchTerm]);

  useEffect(() => {
    if (players) {
      setPlayersCount(players?.length);

      setSelectedPagePlayers(
        players.slice(offset.current, playersPerPage + offset.current)
      );
    }
  }, [players]);

  const titleSection = (
    <DialogTitle sx={customStyles.titleStack}>
      {'Player Search'}

      <IconButton onClick={onClose}>
        <CloseIcon sx={iconStyle} />
      </IconButton>
    </DialogTitle>
  );

  const playerSearchSection = (
    <>
      <TextSearchField
        placeholder="Search Player Name"
        variant="outlined"
        fullWidth
        onSearchChange={(text) => searchPlayersByName(text)}
        size="small"
        waitLimit={300}
        sx={isPerformanceLabPage ? {} : customStyles.search}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={iconStyle} />
            </InputAdornment>
          ),
        }}
        autoFocus
      />

      <Box sx={customStyles.playerCountBox}>
        {isPlayersFetching ? (
          <Skeleton variant="text" sx={customStyles.playerCountSkeleton} />
        ) : (
          <Typography>
            {`${
              searchTermLength.current && playersCount
                ? playersCount
                : defaultPlayerCount
            } Players found`}
          </Typography>
        )}
      </Box>
    </>
  );

  const filteredPlayersSection = (
    <>
      {searchTermLength.current > 0 && (
        <Stack sx={customStyles.filteredPlayersStack}>
          {selectedPagePlayers.map((player, index) => (
            <Box
              key={player.id}
              onClick={() => onPlayerSelected(player)}
              sx={(theme) => ({
                ...customStyles.link,
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              })}
            >
              <EplPlayerRow
                player={player}
                index={index}
                playersPerPage={playersPerPage}
                isPerformanceLabPage={isPerformanceLabPage}
              />
            </Box>
          ))}
        </Stack>
      )}
    </>
  );

  const paginationSection = (
    <>
      {searchTermLength.current > 0 && (
        <Stack sx={customStyles.paginationStack}>
          <ShowingPage page={page} lastPage={lastPage} />

          <Pagination
            sx={customStyles.pagination}
            count={lastPage}
            color="primary"
            page={page}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: KeyboardArrowLeftIcon,
                  next: KeyboardArrowRightIcon,
                }}
                {...item}
              />
            )}
            onChange={(_, value) => setPageData(value)}
          />
        </Stack>
      )}
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box sx={customStyles.mainBox}>
        {titleSection}

        <DialogContent>
          {playerSearchSection}

          {isPlayersFetching && <PlayerSearchModalSkeletons />}

          {!isPlayersFetching && filteredPlayersSection}

          {!isPlayersFetching &&
            players &&
            players.length > 0 &&
            paginationSection}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default EPLPlayerSearchModal;

const customStyles = {
  titleStack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pr: 3,
  },
  filteredPlayersStack: {
    px: 1,
  },
  paginationStack: {
    px: 6,
    pt: 2,
    pb: 4,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 12,
  },
  pagination: {
    mt: 2,
  },
  mainBox: {
    width: 600,
    overflowX: 'hidden',
    height: 600,
  },
  playerCountBox: {
    mt: 2,
    pl: 0,
  },
  playerCountSkeleton: {
    width: 160,
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    cursor: 'pointer',
    py: 3,
  },
  search: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      background: '#F6F6F6',
    },
  },
  icon: {
    color: 'black',
  },
};
