import { Box, Divider, Skeleton, Stack } from '@mui/material';

const checkIsLastItem = (index: number) => index + 1 !== 5;

const PlayerSearchModalSkeletons = () => (
  <>
    {Array.from({ length: 5 }).map((_, index) => (
      <Stack key={index} mt={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton variant="circular" height={48} width={48} />

            <Box>
              <Skeleton variant="text" width="20vw" />
              <Skeleton variant="text" width="10vw" />
            </Box>
          </Stack>

          <Skeleton variant="rectangular" height={30} width={50} />
        </Stack>

        {checkIsLastItem(index) && <Divider />}
      </Stack>
    ))}

    <Stack sx={{ alignItems: 'center' }} mt={10}>
      <Skeleton variant="text" width="10vw" />
      <Stack direction="row" spacing={2} mt={2}>
        {Array.from({ length: 8 }).map((_, index) => (
          <Skeleton key={index} variant="circular" height={30} width={30} />
        ))}
      </Stack>
    </Stack>
  </>
);

export default PlayerSearchModalSkeletons;
