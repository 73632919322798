import { AgeGroupCalculationMethod } from 'components/pages/ClubSettings/pageHelpers';

// Create a list of types for any lookup of the current user role from the ID
export const AiSCOUTRoleList = [
  '1:AiSCOUTAppointedPerson',
  '2:HeadOfRecruitment',
  '3:HeadScout',
  '4:LocalOrRegionalScout',
  '5:LeadOrHeadCommercial',
  '6:CommercialStaff',
  '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
  '8:SportScienceStaff',
  '9:AiSCOUTStaff',
  '10:Scout',
  '11:Turk',
  '12:AiLabsUser',
  '13:AiLabsExternalStaff',
  '14:MLSNext',
  '15:RecruitmentCentre',
] as const;

// This is the type of the current user role
export type AiSCOUTRole = typeof AiSCOUTRoleList[number];

export const convertRoleIDtoAiSCOUTRoleType = (roleId: number): AiSCOUTRole => {
  const role = AiSCOUTRoleList.find((role) => {
    return role.split(':')[0] === roleId.toString();
  });

  if (role) {
    return role;
  } else {
    throw new Error(`Role ID ${roleId} is not a valid AiSCOUT role`);
  }
};

export interface CurrentUser {
  /**
   * The current user object
   */
  user: User;
  /**
   * Club information for the current user.
   */
  club: Club;
}

export interface Club {
  /**
   * The ID of the currently active club
   */
  activeClubId: number;
  /**
   * Active AiScout Club Id (Levi9 DB)
   */
  activeAiScoutClubId: string;
  /**
   * Active club name
   */
  name: string;
  /**
   * The colour to use for the active club
   */
  color: string;
  /**
   * The logo to use for the active club
   */
  logo: string;
  /**
   * Other possible clubs the user is a member of
   */
  inactiveClubs: InactiveClub[];
  /**
   * Club settings
   */
  clubSettings: ClubSettings;
}

export interface InactiveClub {
  /**
   * The ID of the club
   */
  id: string;
  /**
   * The name of the club
   */
  name: string;
  /**
   * The logo of the club
   */
  logo: string;
}

export interface ClubSettings {
  /**
   * The age group calculation method
   */
  ageGroupCalculationMethod: AgeGroupCalculationMethod;
}

export interface User {
  /**
   * The ID of the current user
   */
  userId: string;

  /**
   * The aiSCOUT userId of the user (Levi9 DB)
   */
  userId_aiscout: string;
  /**
   * The email of the current user.
   */
  email: string;
  /**
   * The name of the current user
   */
  name: string;
  /**
   * The Role ID of the current user.
   */
  roleId: number;

  /**
   * The current user's AiSCOUTRole
   */
  aiSCOUTRole?: AiSCOUTRole;

  /**
   * The current AiSCOUT Access Token
   */
  accessToken: string;
  /**
   * The current AiSCOUT Refresh Token
   */
  refreshToken: string;
  /**
   * The AiSCOUT Scout ID of the user
   */
  scoutId: number;
  /**
   * The AiSCOUT Scout ID of the user in the levi9 aiscout database
   */
  scoutId_aiscout: string;
  /**
   * The Profile Image of the user
   */
  profileImage: string;
  /**
   * Turk Groups
   */
  groups: UserGroup[];
  /**
   * Preferred unit system (height, weight, etc.)
   */
  preferredUnitSystem: UnitSystem;

  /**
   * aiScout Access Token
   */
  accessTokenAiscout?: {
    /**
     * The access token used to interact with the levi9 backend api
     */
    access_token: string;
    /**
     * The time at which the access token expires
     */
    access_token_expires_at: Date;
  } | null;
}

export type CurrentUserWithRole = {
  club: Club;
  user: Required<User>;
};

export const addAiSCOUTRoleToUser = (
  currentUser: CurrentUser
): CurrentUserWithRole => {
  let modifiedCurrentUser = currentUser;
  modifiedCurrentUser.user = {
    ...modifiedCurrentUser.user,
    aiSCOUTRole: convertRoleIDtoAiSCOUTRoleType(currentUser.user.roleId),
  };
  return modifiedCurrentUser as CurrentUserWithRole;
};

// List of turk groups that a user can have
export const UserGroupList = [
  'BenchmarkTurk',
  'SuperTurk',
  'Turk',
  'TurkAdmin',
  'AiLabsCast',
] as const;

export type UserGroup = typeof UserGroupList[number];

export const allUnitSystems = ['metric', 'imperial'] as const;

export type UnitSystem = typeof allUnitSystems[number];
