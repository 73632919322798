import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, IconButton, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useCast } from '../CastService';
import CastIcon from '../Icons/CastIcon';
import Clubs from './Clubs';
import { getIconStyle, getPageBasedStyles } from './helper';
import PlayerSearch from './PlayerSearch';
import UserProfileDropdown from './UserProfileDropdown';
import ThemeSwitcher from 'components/shared/Header/ThemeSwitcher';
import { castActiveState, pinSidebarState } from 'state/atoms';
import { AISCOUT_CLUB_UUID } from 'helpers/constants';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';
import { api } from 'helpers/trpc/pages';

const paddingLeft = {
  xs: 3.5,
  sm: 3.5,
  md: 3.5,
  lg: 26.25,
  xl: 26.5,
};

const Header = () => {
  const router = useRouter();
  const getShortLivedLoginTokenAndUrlMutation =
    api.shared.getShortLivedLoginTokenAndUrl.useMutation();
  const cast = useCast();
  const theme = useTheme();
  const [pinSidebar, setPinSidebar] = useRecoilState(pinSidebarState);
  const castActive = useRecoilValue(castActiveState);

  const { data: currentUser } = api.shared.currentUser.useQuery();

  const isPerformanceLabPage = getPerformanceLabPagePaths().includes(
    router.pathname
  );

  const isAiScoutStaff = currentUser?.user.aiSCOUTRole === '9:AiSCOUTStaff';

  const isAiScoutClub =
    currentUser?.club.activeAiScoutClubId === AISCOUT_CLUB_UUID;

  const showCastButton =
    isAiScoutStaff && currentUser.user.groups.includes('AiLabsCast');

  if (castActive) {
    return null;
  }

  const handleCastPage = async () => {
    if (currentUser?.user.roleId !== 9) {
      return null;
    }
    const tokenAndUrl =
      await getShortLivedLoginTokenAndUrlMutation.mutateAsync();

    const urlToBeCast = new URL(window.location.href);

    // Set our cast query param to true so that we disable the page frame.
    urlToBeCast.searchParams.set('cast', 'true');

    const loginUrlWithRedirect = new URL(tokenAndUrl.loginUrl);
    loginUrlWithRedirect.searchParams.set(
      tokenAndUrl.callbackSearchParamName,
      urlToBeCast.toString()
    );

    const url = loginUrlWithRedirect.toString();

    console.log({ url }); // * This URL used is used for dev testing

    await cast({ url });
  };

  const customStyles = {
    wrapperContainer: {
      position: 'fixed',
      top: theme.palette.mode === 'light' ? 0.25 : 0.5,
      right: 0,
      left: 0,
      pr: theme.palette.mode === 'light' ? 4 : 4.25,
      pl: paddingLeft,
      zIndex: 1030,
      background:
        theme.palette.mode === 'light'
          ? getPageBasedStyles(isPerformanceLabPage, currentUser?.club.color)
              .wrapper
          : '#051521',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '0px',
      py: 4.6,
      background:
        theme.palette.mode === 'light'
          ? getPageBasedStyles(isPerformanceLabPage, currentUser?.club.color)
              .box
          : undefined,
    },
    innerBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 0.75,
    },
    menuButton: {
      ml: 2,
      ...getIconStyle(theme.palette.mode, isPerformanceLabPage),
    },
    castButton: {
      color: 'white',
      ':hover': {
        backgroundColor: '#FFFFFF33',
      },
    },
  };

  const castButton = !isPerformanceLabPage && isAiScoutStaff && (
    <IconButton onClick={handleCastPage} sx={customStyles.castButton}>
      <CastIcon />
    </IconButton>
  );

  const menuIconButton = (
    <IconButton
      onClick={() => {
        setTimeout(() => setPinSidebar((prevPinSidebar) => !prevPinSidebar), 0);
      }}
      sx={customStyles.menuButton}
    >
      {pinSidebar ? <MenuIcon /> : <MenuOpenIcon />}
    </IconButton>
  );

  return (
    <Box sx={customStyles.wrapperContainer}>
      <Box component="header" sx={customStyles.box}>
        {menuIconButton}

        <Box sx={customStyles.innerBox}>
          {isAiScoutClub && isAiScoutStaff && (
            <PlayerSearch isPerformanceLabPage={isPerformanceLabPage} />
          )}

          {showCastButton && castButton}

          {isPerformanceLabPage && <ThemeSwitcher />}

          <Clubs
            isPerformanceLabPage={isPerformanceLabPage}
            theme={theme.palette.mode}
          />

          <UserProfileDropdown
            isPerformanceLabPage={isPerformanceLabPage}
            theme={theme.palette.mode}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
