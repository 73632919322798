import { AiSCOUTRole } from 'helpers/userRoleHelpersAndTypes';

export const higherLevelScoutRoles: AiSCOUTRole[] = [
  '1:AiSCOUTAppointedPerson',
  '2:HeadOfRecruitment',
  '3:HeadScout',
  '9:AiSCOUTStaff',
  '14:MLSNext',
  '15:RecruitmentCentre',
];

export const higherLevelScoutRoleIds = higherLevelScoutRoles.map((role) =>
  parseInt(role.split(':')[0])
);

export const isUserHigherLevelScout = (roleId: number | undefined) => {
  if (!roleId) {
    return false;
  }

  return higherLevelScoutRoleIds.includes(roleId);
};
