import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  PaletteMode,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { getIconStyle } from './helper';
import { MenuItemFontStyles } from 'components/shared/PlayerManagementDropdown';
import { trpc } from 'helpers/trpc';

interface Props {
  isPerformanceLabPage: boolean;
  theme: PaletteMode;
}

const Clubs = ({ isPerformanceLabPage, theme }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const { data: currentUser, refetch: refetchCurrentUser } =
    trpc.shared.currentUser.useQuery();

  const switchClubMutation = trpc.shared.switchActiveClub.useMutation();

  const hasInactiveClubs = Boolean(
    currentUser && currentUser?.club.inactiveClubs.length > 0
  );
  const open = Boolean(anchorEl);

  const changeClub = async (clubId: string) => {
    enqueueSnackbar('Switching club...', { variant: 'info' });

    try {
      await switchClubMutation.mutateAsync({
        clubId,
      });

      enqueueSnackbar('Successful club switch', { variant: 'success' });
    } catch {
      enqueueSnackbar('Unsuccessful club switch', { variant: 'error' });
    }

    await refetchCurrentUser();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!hasInactiveClubs) {
    return null;
  }

  return (
    <Box>
      <Button
        variant="text"
        onClick={(event) => setAnchorEl(event.currentTarget!)}
        sx={getIconStyle(theme, isPerformanceLabPage)}
      >
        <img
          src={currentUser?.club.logo}
          alt="club badge"
          width={25}
          height={25}
        />

        <ArrowDropDownIcon sx={getIconStyle(theme, isPerformanceLabPage)} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {currentUser?.club.inactiveClubs.map((inactiveClub) => (
          <MenuItem
            key={inactiveClub.id}
            onClick={() => changeClub(inactiveClub.id)}
            sx={MenuItemFontStyles}
          >
            <Image src={inactiveClub.logo} alt="badge" width={20} height={20} />

            <Typography ml={3}>{inactiveClub.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Clubs;
