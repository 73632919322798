import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { env } from 'env.mjs';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilValue } from 'recoil';

import LoadingText from './LoadingText';
import { themeState } from 'state/atoms';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';

interface Props {
  children?: React.ReactNode;
  customTheme?: 'light' | 'dark';
}

const Spinner = ({ children, customTheme }: Props) => {
  const theme = useRecoilValue(themeState);
  const router = useRouter();

  const svgFill = (customTheme || theme) === 'light' ? 'black' : 'white';
  const isPerformanceLabPath = getPerformanceLabPagePaths().includes(
    router.pathname
  );
  const svgColor = isPerformanceLabPath ? svgFill : 'white';

  const isOciDeployment = env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI';

  return (
    <Box
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white !important',
      }}
    >
      {isOciDeployment ? (
        <CircularProgress
          style={{
            color: svgColor,
          }}
        />
      ) : (
        <svg
          width="59"
          height="59"
          viewBox="0 0 59 59"
          fill="none"
          color="white !important"
          xmlns="http://www.w3.org/2000/svg"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0"
            to="359"
            dur="2s"
            repeatCount="indefinite"
          />
          <g clipPath="url(#clip0_3546_99152)">
            <path
              d="M20.094 13.0053L13.6444 1.83453H26.532L20.094 13.0053Z"
              fill={svgColor}
            />
            <path
              d="M52.3073 46.4829L58.7569 35.3237H45.8693L52.3073 46.4829Z"
              fill={svgColor}
            />
            <path
              d="M7.20644 57.6537L13.6444 46.4828H0.756836L7.20644 57.6537Z"
              fill={svgColor}
            />
            <path
              d="M26.532 1.83453L0.756836 46.4829H52.3072L26.532 1.83453ZM26.532 24.1645L32.9816 35.3237H20.094L26.532 24.1645Z"
              fill={svgColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_3546_99152">
              <rect
                width="58"
                height="58"
                fill="white"
                transform="translate(0.756836 0.744141)"
              />
            </clipPath>
          </defs>
        </svg>
      )}

      {children && (
        <LoadingText>
          <div style={{ marginTop: '18px' }}>{children}</div>
        </LoadingText>
      )}
    </Box>
  );
};

export default Spinner;
